import about01 from '../assets/about01.jpg';
import about02 from '../assets/about02.jpg';
import gallery02 from '../assets/gallery02.jpg';
import gallery01 from '../assets/gallery01.jpg';
import gallery03 from '../assets/gallery03.jpg';
import gallery04 from '../assets/gallery04.jpg';
import gallery05 from '../assets/gallery05.jpg';
import gallery06 from '../assets/gallery06.jpg';
import gallery07 from '../assets/gallery07.jpg';
import about04 from '../assets/about04.jpg';
import about03 from '../assets/about03.jpg';
import footer_logo from '../assets/footer_logo.png';
import himanshu from '../assets/himanshu.jpg'
import himanshu2 from '../assets/himanshu2.jpg'
import himanshu3 from '../assets/himanshu3.jpg'
import himanshu5 from '../assets/himanshu5.jpg'


const images = {
  about01,
  about02,
  about03,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  about04,
  himanshu,
  himanshu2,
  himanshu3,
  himanshu5,
  footer_logo
}
export default images;